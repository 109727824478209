import {ObjectList} from './interface/object-list.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {Serializable} from "../interface/serializable.interface";
import {Selectable} from "../interface/selectable.interface";

export interface ILessorGroup {
  id: number;
  name: string;
}

export class LessorGroup implements Serializable, Selectable {
  private readonly _name: string;
  private readonly _id: number;

  constructor(legalEntity: ILessorGroup) {
    this._name = legalEntity.name;
    this._id = legalEntity.id;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

  toText(): string {
    return this.name;
  }

  toHTML(): string {
    return this.name;
  }

  toSelectText(): string {
    return this.name;
  }
}

export class LessorGroupList extends ObjectList<LessorGroup, ILessorGroup> {
  protected mapToListElement(instance: ILessorGroup): LessorGroup {
    return new LessorGroup(instance);
  }

  protected mapToSelectableItem(instance: LessorGroup): ISimpleSelectableData {
    return {
      label: instance.toSelectText(),
      value: instance.id
    };
  }

}
