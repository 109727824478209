import {Component, forwardRef} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CustomerService} from "../../../core/services/customer.service";
import {map} from "rxjs/operators";
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-search-lessor-group',
  templateUrl: './search-lessor-group.component.html',
  styleUrls: ['./search-lessor-group.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchLessorGroupComponent),
      multi: true,
    },
  ],
})
export class SearchLessorGroupComponent  extends AbstractControlComponent  {
  groupForm: FormControl;

  onTouched: () => void = () => {
  }

  constructor(private formBuilder: FormBuilder,
              private customerService: CustomerService) {
    super();
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.groupForm.reset();
    } else {
      this.groupForm.setValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.groupForm.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.groupForm.disable() : this.groupForm.enable();
  }

  ngOnInit(): void {
    this.groupForm = this.formBuilder.control(null);
  }

  public groupSearchFunction = (text: string, page: number, size: number) => {
    return this.customerService.searchGroups(text, page, size).pipe(
      map(data => data.toSimpleSelectableDataList())
    )
  }

  public groupFindByIdFunction = (groupId) => {
    return this.customerService.getGroup(groupId).pipe(
      map(group => group.toSelectText())
    )
  };

  clear() {
    this.groupForm?.reset();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

}
