import {Injectable} from '@angular/core';
import {EventBusService} from './event-bus.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowNameService {
  private readonly SEPARATOR = ' > ';
  private TRANSLATIONS = new Map<String, String>();

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _eventBusService: EventBusService) {
    this.initTranslations();
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const path = this.map(event);
      this._eventBusService.changeWindowName(path);
    });
  }

  private map(val: NavigationEnd) {
    return val.url.split('/')
      .filter(this.notEmpty)
      .map(segment => segment.indexOf('?') < 0 ? segment : segment.substring(0, segment.indexOf('?')))
      .map(segment => this.translate(segment))
      .join(this.SEPARATOR);
  }

  private notEmpty(text: string) {
    return text !== '';
  }

  private translate(segment: string) {
    return this.TRANSLATIONS.get(segment) ?? segment;
  }

  private initTranslations() {
    this.TRANSLATIONS.set('home','Widok główny')
    this.TRANSLATIONS.set('my-account','Moje konto')
    this.TRANSLATIONS.set('admin','Panel administracyjny')
    this.TRANSLATIONS.set('post','Poczta')
    this.TRANSLATIONS.set('statistics','Statystyki')
    this.TRANSLATIONS.set('monitored-payments', 'Monitorowane płatności')
    this.TRANSLATIONS.set('international-cases', 'Sprawy międzynarodowe')
    this.TRANSLATIONS.set('previndications', 'Prewindykacja')
    this.TRANSLATIONS.set('vindications', 'Windykacja')
    this.TRANSLATIONS.set('initial-verifications', 'Weryfikacja wstępna')
    this.TRANSLATIONS.set('relocations-and-releases', 'Relokacja i odbiór')
    this.TRANSLATIONS.set('regulars', 'Rachunki regularne')
    this.TRANSLATIONS.set('criminal-cases', 'Sprawy karne')
    this.TRANSLATIONS.set('civil-cases', 'Sprawy cywilne')
    this.TRANSLATIONS.set('administration-cases', 'Sprawy administracyjne')
    this.TRANSLATIONS.set('accountancy', 'Księgowość')
    this.TRANSLATIONS.set('searcher', '')
    this.TRANSLATIONS.set('strategies', 'Strategie')
    this.TRANSLATIONS.set('unauthorized-access', 'Nieautoryzowany dostęp')
    this.TRANSLATIONS.set('(aux:details)', 'Szczegóły rachunku')
    this.TRANSLATIONS.set('(aux:contracts)', 'Umowy')
    this.TRANSLATIONS.set('(aux:verification-protocols)', 'Protokoły weryfikacyjne')
    this.TRANSLATIONS.set('(aux:arrangements)', 'Ustalenia')
    this.TRANSLATIONS.set('(aux:deadlines)', 'Terminy')
    this.TRANSLATIONS.set('(aux:events)', 'Zdarzenia')
    this.TRANSLATIONS.set('(aux:debts)', 'Koszty')
    this.TRANSLATIONS.set('(aux:payments)', 'Wpłaty')
    this.TRANSLATIONS.set('(aux:parcels)', 'Przesyłki')
    this.TRANSLATIONS.set('(aux:files)', 'Załączniki')
    this.TRANSLATIONS.set('(aux:remarks)', 'Uwagi')
    this.TRANSLATIONS.set('(aux:notifications)', 'Przypomnienia')
    this.TRANSLATIONS.set('(aux:contact)', 'Kontakt')
    this.TRANSLATIONS.set('(aux:chats)', 'Czaty')
    this.TRANSLATIONS.set('(aux:duties)', 'Zadania')
    this.TRANSLATIONS.set('(aux:history)', 'Historia')
    this.TRANSLATIONS.set('(aux:smart-searcher)', 'Wyszukiwarka informacji')
    this.TRANSLATIONS.set('(aux:actions)', 'Czynności')
    this.TRANSLATIONS.set('(aux:connections)', 'Powiązania')
    this.TRANSLATIONS.set('service', 'Serwis')
    this.TRANSLATIONS.set('register', 'Rejestracja')
    this.TRANSLATIONS.set('regularity-manager', 'Manager Regularności')
    this.TRANSLATIONS.set('capital-recovery-manager', 'Manager Odzyskiwania Kapitału')
    this.TRANSLATIONS.set('my-cases', 'Moje sprawy')
  }
}
