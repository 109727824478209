import {Injectable} from '@angular/core';
import {SnackBarService} from './snack-bar.service';

export enum STORAGE_KEY {
  USER = ('gd360-user'),
  SEARCH_PARAMS = ('gd360-search-params'),
  PAGINATION_SIZE = ('gd360-pagination-size'),
  PROCESSWAY_PERSONALIZATION = ('pway-v2-personalization'),
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private snackBarService: SnackBarService) {
  }

  save(key: STORAGE_KEY, value: any) {
    localStorage.setItem(key.toString(), JSON.stringify(value));
  }

  saveRaw(key: STORAGE_KEY, value: string) {
    localStorage.setItem(key.toString(), value);
  }

  get(key: STORAGE_KEY) {
    const value = localStorage.getItem(key.toString());
    return JSON.parse(value);
  }

  getNotNull(key: STORAGE_KEY) {
    const value = this.get(key);
    if (!value) {
      this.snackBarService.displayText(`Pusta wartość parametru ${key}`);
      throw new Error(`Empty value of ${key}`);
    }

    return value;
  }

  remove(key: STORAGE_KEY) {
    localStorage.removeItem(key.toString());
  }
}

