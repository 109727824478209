import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";
import {MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector: 'app-simple-number-value-accessor',
  templateUrl: './simple-number-value-accessor.component.html',
  styleUrls: ['./simple-number-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleNumberValueAccessorComponent),
      multi: true
    },
  ],
})
export class SimpleNumberValueAccessorComponent extends AbstractControlComponent{
  @Input() label;
  @Input() required = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  numberForm: FormControl;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
  }

  private createForm() {
    this.numberForm = this.formBuilder.control(null);
  }

  registerOnChange(fn: any): void {
    this.numberForm.valueChanges.subscribe(fn);
  }

  writeValue(value: number): void {
    this.numberForm.setValue(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.numberForm.disable() : this.numberForm.enable();
  }

  clear() {
    this.numberForm.reset();
  }

  inputChanged(value: number) {
    if (value == null) {
      this.clear();
    }
  }
}
