<mat-form-field [appearance]="appearance" class="width-100">
  <mat-progress-bar mode="buffer" *ngIf="!data"></mat-progress-bar>
  <mat-label>{{label}}</mat-label>
  <mat-select class="width-100"
              [formControl]="selectFormControl"
              [placeholder]="label"
              [compareWith]="compareWith"
              [required]="required"
              multiple>
    <mat-option>
      <ngx-mat-select-search [formControl]="dataMultiFilterCtrl" placeholderLabel="Szukaj" noEntriesFoundLabel=""></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let instance of filteredDataMulti | async" [value]="instance.value">
      {{instance.label}}
      <button mat-button
              matSuffix
              mat-icon-button
              appClickStopPropagation
              *ngIf="instance.tooltip"
              (click)="peek(instance)">
        <mat-icon>visibility</mat-icon>
      </button>
    </mat-option>
  </mat-select>
  <button mat-button
          matSuffix
          mat-icon-button
          appClickStopPropagation *ngIf="this.selectFormControl.value"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
