import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberRangeComponent),
      multi: true,
    },
  ],
})
export class NumberRangeComponent extends AbstractControlComponent {
  @Input() label;
  rangeForm: FormGroup;


  constructor(private formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
  }

  private createForm() {
    this.rangeForm = this.formBuilder.group({
      from: [null],
      to: [null]
    });
  }

  registerOnChange(fn: any): void {
    this.rangeForm.valueChanges.subscribe(fn);
  }



  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.rangeForm.disable() : this.rangeForm.enable();
  }

  writeValue(obj: any): void {
  }

  clear() {
    this.rangeForm.reset();
  }

}
