<mat-form-field [appearance]="appearance" class="width-100">
  <mat-label>{{label}}</mat-label>
  <input #input
         matInput
         type="number"
         (ngModelChange)="inputChanged($event)"
         appOnlyIntegers
         appBlurOnEnter
         [required]="required"
         [formControl]="numberForm"
         [placeholder]="label">
  <button mat-button
          matSuffix
          mat-icon-button
          *ngIf="input.value"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
