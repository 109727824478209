import dictionary from '../../../assets/dictionary.json';

export enum DictionaryPosition {
  CUSTOM_MESSAGE = ('CUSTOM_MESSAGE'),
  INSUFFICIENT_PERMISSIONS = ('INSUFFICIENT_PERMISSIONS'),
  UNKNOWN_KEY = ('UNKNOWN_KEY'),
  INVALID_CREDENTIALS = ('INVALID_CREDENTIALS'),
  EMPLOYEE_BLOCKED = ('EMPLOYEE_BLOCKED'),
  EMPLOYEE_UNLOCKED = ('EMPLOYEE_UNLOCKED'),
  EMPLOYEE_INFO_CHANGED = ('EMPLOYEE_INFO_CHANGED'),
  PASSWORD_RESET = ('PASSWORD_RESET'),
  ADDED_EMPLOYEE = ('ADDED_EMPLOYEE'),
  ADDED_EVENT = ('ADDED_EVENT'),
  DELETED_FILE = ('DELETED_FILE'),
  CLOSE = ('CLOSE'),
  SIGN_IN = ('SIGN_IN'),
  EMAIL = ('EMAIL'),
  PASSWORD = ('PASSWORD'),
  EMAIL_IS_REQUIRED = ('EMAIL_IS_REQUIRED'),
  PASSWORD_IS_REQUIRED = ('PASSWORD_IS_REQUIRED'),
  NOT_FOUND = ('NOT_FOUND'),
  NOT_FOUND_EMPLOYEE = ('NOT_FOUND_EMPLOYEE'),
  NOT_FOUND_VINDICATION = ('NOT_FOUND_VINDICATION'),
  NOT_FOUND_CUSTOMER = ('NOT_FOUND_CUSTOMER'),
  UNDO = ('UNDO'),
  VINDICATOR_CHANGED = ('VINDICATOR_CHANGED'),
  PAYMENT_ADDED = ('PAYMENT_ADDED'),
  VINDICATION_DELETED = ('VINDICATION_DELETED'),
  OK = ('OK'),
  RESOURCE_ALREADY_EXISTS = ('RESOURCE_ALREADY_EXISTS'),
  PASSWORD_SET = ('PASSWORD_SET'),
  INVALID_JWT_TOKEN = ('INVALID_JWT_TOKEN'),
  INVALID_OLD_PASSWORD = ('INVALID_OLD_PASSWORD'),
  FILE_UPLOADED = ('FILE_UPLOADED'),
  FIELD_ATTORNEY_CHANGED = ('FIELD_ATTORNEY_CHANGED'),
  ADDED_COMPANY = ('ADDED_COMPANY'),
  COMPANY_INFO_CHANGED = ('COMPANY_INFO_CHANGED'),
  ADDED_PRIVATE_PERSON = ('ADDED_PRIVATE_PERSON'),
  PRIVATE_PERSON_INFO_CHANGED = ('PRIVATE_PERSON_INFO_CHANGED'),
  DEBT_UPDATED = ('DEBT_UPDATED'),
  ADDED_SUPERVISOR = ('ADDED_SUPERVISOR'),
  MINIMAL_CONTRACT_AMOUNT_EXCEPTION = ('MINIMAL_CONTRACT_AMOUNT_EXCEPTION'),
  CURRENCY_NOT_MATCH = ('CURRENCY_NOT_MATCH'),
  INVALID_PAYMENT = ('INVALID_PAYMENT'),
  LOGGED_OUT = ('LOGGED_OUT'),
  SERVER_ERROR = ('SERVER_ERROR'),
  CONNECTED_TO_WS = ('CONNECTED_TO_WS'),
  ADDED_LEGAL_CASE = ('ADDED_LEGAL_CASE'),
  IZZY_CHECK_ERROR = ('IZZY_CHECK_ERROR'),
  MOVED_TO_INVOICE = ('MOVED_TO_INVOICE'),
  ADDED_SERVICE = ('ADDED_SERVICE'),
  DEPARTMENT_CHANGED = ('DEPARTMENT_CHANGED'),
  VEHICLE_NOT_FOUND = ('VEHICLE_NOT_FOUND'),
  OUTBOX_BOOK_SEND = ('OUTBOX_BOOK_SEND'),
  SEND_FOR_REPORT = ('SEND FOR REPORT'),
  FORBIDDEN = ('FORBIDDEN'),
}

export enum LANGUAGE {
  PL,
  EN
}

interface IPosition {
  key: string;
  pl: string;
  en: string;
}

export class Dictionary {
  private readonly _dictionary: IPosition[];

  constructor() {
    this._dictionary = dictionary;
  }

  static fromString(key: string): DictionaryPosition {
    const value = DictionaryPosition[key];
    return value ? value : DictionaryPosition.UNKNOWN_KEY;
  }

  polish(key: DictionaryPosition): string {
    const position: IPosition = this.find(key);
    return position ? position.pl : key.toString();
  }

  english(key: DictionaryPosition): string {
    const position: IPosition = this.find(key);
    return position ? position.en : key.toString();
  }

  private find(key: DictionaryPosition): IPosition {
    const keyString = key.toString();
    return this._dictionary.find(element => {
      return element.key === keyString;
    });
  }
}
