import {Component, OnInit} from '@angular/core';
import {SidenavService} from '../../services/sidenav.service';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {User} from 'src/app/shared/model/user.model';
import {LoaderService} from '../../services/loader.service';
import {AuthorizationService} from '../../services/authorization.service';
import {DialogOpener} from '../../../shared/utils/dialog-opener';
import {
  LegalCaseSearcherDialogComponent
} from '../../../shared/dialog/legal-case-searcher-dialog/legal-case-searcher-dialog.component';
import {
  LoadLegalCasePaymentsComponent
} from '../../../shared/legal-case-shared/load-legal-case-payments/load-legal-case-payments.component';
import {STORAGE_KEY, StorageService} from "../../services/storage.service";
import {ImportPaymentsComponent} from "../../../shared/dialog/import-payments/import-payments.component";
import {EventsSummaryComponent} from "../../../shared/dialog/events-summary/events-summary.component";
import {ImportGpsComponent} from "../../../shared/dialog/import-gps/import-gps.component";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  isOpened = false;
  authenticatedEmployee: User;

  constructor(
    private sidenavStateService: SidenavService,
    public router: Router,
    private loader: LoaderService,
    private storageService: StorageService,
    private dialogOpener: DialogOpener,
    private authorizationService: AuthorizationService,
  ) {
    sidenavStateService
      .isSidenavOpenedSubject()
      .subscribe((isOpened: boolean) => {
        this.isOpened = isOpened;
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.hide();
      }
    });
  }

  gotoMainWindow() {
    this.router.navigate(['/home']);
  }

  gotoMyAccount() {
    this.router.navigate(['/my-account']);
  }

  sidenavOpened() {
    this.sidenavStateService.updateSidenavOpened(true);
  }

  sidenavClosed() {
    this.sidenavStateService.updateSidenavOpened(false);
  }

  gotoAdminPanel() {
    this.router.navigate(['/admin']);
  }

  gotoPost() {
    this.router.navigate(['/post']);
  }

  gotoStatistics() {
    this.router.navigate(['/statistics']);
  }

  gotoAccountancy() {
    this.router.navigate(['/accountancy']);
  }

  isAdmin() {
    return this.authorizationService.isAdmin();
  }

  goToEventsWindow() {
    this.storageService.remove(STORAGE_KEY.SEARCH_PARAMS);
    this.dialogOpener.getNonNullDialogResponseObservable(EventsSummaryComponent)
      .subscribe();
  }

  gotoSearchWindow() {
    this.storageService.remove(STORAGE_KEY.SEARCH_PARAMS);
    this.dialogOpener.getNonNullDialogResponseObservable(LegalCaseSearcherDialogComponent)
      .subscribe(params => this.router.navigate(['/searcher'], {
        queryParams: params
      }));
  }

  gotoLoadPayments() {
    this.dialogOpener.open(LoadLegalCasePaymentsComponent);
  }

  get copyrights() {
    const year = new Date().getFullYear();
    return `Copyright ©${year} RJ Digital Management. All Rights Reserved.`;
  }

  gotoStrategyPanel() {
    this.router.navigate(['/strategies']);
  }

  gotoForm() {
    window.open(`https://forms.hive.com/?formId=48h9gFRQLS8hiJGmo`, '_blank');
  }


  importPayments() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportPaymentsComponent, {}).subscribe(_ => {});
  }

  importGps() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportGpsComponent, {}).subscribe(_ => {});
  }

  gotoManagePanel() {
    this.router.navigate(['/manage']);
  }
}
