import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  ISimpleSelectableData,
  ISimpleSelectableDataList
} from '../model/interface/simple-selectable-data.model.interface';
import {HtmlViewerComponent} from "../html-viewer/html-viewer.component";
import {DialogOpener} from "../utils/dialog-opener";
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {ReplaySubject, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-simple-multiple-select-value-accessor',
  templateUrl: './simple-multiple-select-value-accessor.component.html',
  styleUrls: ['./simple-multiple-select-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleMultipleSelectValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleMultipleSelectValueAccessorComponent extends AbstractControlComponent implements OnDestroy {
  @Input() required = false;
  @Input() label: string;
  @Input() data: ISimpleSelectableDataList;
  @Input() defaultSelectedValues = [];
  @Input() appearance: MatFormFieldAppearance = 'outline';

  selectFormControl: FormControl;
  dataMultiFilterCtrl: FormControl<string> = new FormControl<string>('');
  filteredDataMulti: ReplaySubject<ISimpleSelectableData[]> = new ReplaySubject<ISimpleSelectableData[]>(1);
  _onDestroy = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private _dialogOpener: DialogOpener) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.writeValue(this.defaultSelectedValues);

    this.filteredDataMulti.next(this.data?.data.slice());

    this.dataMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });
  }

  protected filterBanksMulti() {
    if (!this.data.data) {
      return;
    }
    let search = this.dataMultiFilterCtrl.value;
    console.log(search)
    if (!search) {
      this.filteredDataMulti.next(this.data.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDataMulti.next(
      this.data.data.filter(bank => bank.label.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  registerOnChange(fn: any): void {
    this.selectFormControl.valueChanges.subscribe(fn);
  }


  writeValue(value: any): void {
    this.selectFormControl.setValue(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.selectFormControl.disable() : this.selectFormControl.enable();
  }

  compareWith(first: number, second: number): boolean {
    if (!first || !second) {
      return false;
    }
    return first === second;
  }

  private createForm() {
    this.selectFormControl = this.formBuilder.control(null);
  }

  clear() {
    this.selectFormControl.reset();
  }

  peek(instance: ISimpleSelectableData) {
    this._dialogOpener.open(HtmlViewerComponent, {data: instance.tooltip})
  }
}
