import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Company, CompanyList} from '../../shared/model/company.model';
import {Observable, Subject} from 'rxjs';
import {PrivatePerson, PrivatePersonList} from '../../shared/model/private-person.model';
import {map} from 'rxjs/operators';
import {INewPrivatePerson} from '../../shared/model/interface/new-private-person.model.interface';
import {INewCompany} from '../../shared/model/interface/new-company.model.interface';
import {ILegalEntity, LegalEntity, LegalEntityList, LegalEntityType} from '../../shared/model/legal-entity.model';
import {IEditCompany} from '../../shared/model/interface/edit-company.model.interface';
import {IObjectList} from '../../shared/model/interface/object-list.model.interface';
import {CustomerFactory} from '../../shared/factory/customer.factory';
import {Cacheable, CacheBuster} from 'ngx-cacheable';
import {HttpParamsFactory} from '../../shared/factory/http-params.factory';
import {IEditPersonalInfo} from '../../shared/model/interface/edit-personal-info.model.interface';
import {INewLessorGroup} from "../../shared/model/interface/new-lessor-group.model.interface";
import {ILessorGroup, LessorGroup, LessorGroupList} from "../../shared/model/lessor-group.model";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {


  constructor(private httpClient: HttpClient) {
  }
  private static _cacheBuster$: Subject<void> = new Subject<void>();
  private URL = environment.backendUrl + '/customers';

  private readonly MAX_PAGE_SIZE = 32000;

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  search(instructing: boolean, text: string, page: number, size: number, instructingId?: number): Observable<LegalEntityList> {
    const params = HttpParamsFactory.notNullsFromObject({
      searchText: text,
      page,
      size,
      instructing,
      instructingId
    });
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL, {params})
      .pipe(
        map(list => CustomerFactory.fromList(list))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getOne(id: number): Observable<LegalEntity> {
    return this.httpClient.get<ILegalEntity>(`${this.URL}/${id}`).pipe(
      map(CustomerFactory.createLegalEntity)
    );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getAllLegalEntities(): Observable<LegalEntityList> {
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL)
      .pipe(
        map(list => CustomerFactory.fromList(list))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getAllCompanies(): Observable<CompanyList> {
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL)
      .pipe(
        map(list => new CompanyList(list))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getAllPrivatePersons(): Observable<PrivatePersonList> {
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL, {params: {
        type: [LegalEntityType.NATURAL_PERSON, LegalEntityType.NATURAL_PERSON_TO_BE_VERIFIED]
      }})
      .pipe(
        map(list => new PrivatePersonList(list))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: CustomerService._cacheBuster$
  })
  createCompany(company: INewCompany, lessorId: number) {
    return this.httpClient.post<ILegalEntity>(environment.backendUrl + `/lessors/${lessorId}/customers`, company)
      .pipe(
        map(c => new Company(c))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: CustomerService._cacheBuster$
  })
  createPrivatePerson(privatePerson: INewPrivatePerson, lessorId: number) {
    return this.httpClient.post<ILegalEntity>(environment.backendUrl + `/lessors/${lessorId}/customers`, privatePerson)
      .pipe(
        map(person => new PrivatePerson(person))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: CustomerService._cacheBuster$
  })
  createGroup(lessorGroup: INewLessorGroup) {
    return this.httpClient.post<ILessorGroup>(this.URL + `/groups`, lessorGroup)
      .pipe(
        map(person => new LessorGroup(person))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getGroup(id: number): Observable<LessorGroup> {
    return this.httpClient.get<ILessorGroup>(`${this.URL}/groups/${id}`).pipe(
      map(data => new LessorGroup(data))
    );
  }

  // @Cacheable({
  //   cacheBusterObserver: CustomerService._cacheBuster$
  // })
  searchGroups(text: string, page: number, size: number): Observable<LessorGroupList> {
    const params = HttpParamsFactory.notNullsFromObject({
      searchText: text,
      page,
      size
    });
    return this.httpClient.get<IObjectList<ILessorGroup>>(this.URL + `/groups`, {params})
      .pipe(
        map(list => new LessorGroupList(list))
      );
  };

  @CacheBuster({
    cacheBusterNotifier: CustomerService._cacheBuster$
  })
  assignGroup(customerId: number, groupId: number) {
    return this.httpClient.put<void>(this.URL + `/${customerId}/groups/${groupId}`, {});
  }

  @CacheBuster({
    cacheBusterNotifier: CustomerService._cacheBuster$
  })
  unassignGroup(customerId: number) {
    return this.httpClient.delete<void>(this.URL + `/${customerId}/groups`);
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getCompany(companyId: number) {
    return this.httpClient.get<ILegalEntity>(this.URL + `/${companyId}`)
      .pipe(
        map(c => new Company(c))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: CustomerService._cacheBuster$
  })
  updateCompany(company: IEditCompany) {
    return this.httpClient.put<ILegalEntity>(this.URL + `/${company.id}`, company)
      .pipe(
        map(c => new Company(c))
      );
  }

  updatePrivatePerson(person: IEditPersonalInfo) {
    return this.httpClient.put<ILegalEntity>(this.URL + `/${person.id}`, person)
      .pipe(
        map(c => new PrivatePerson(c))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getPrivatePerson(privatePersonId: number) {
    return this.httpClient.get<ILegalEntity>(this.URL + `/${privatePersonId}`)
      .pipe(
        map(person => new PrivatePerson(person))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  getAllInstructingEntities(): Observable<LegalEntityList> {
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL, {
      params: {
        instructing: true,
        size: this.MAX_PAGE_SIZE
      }
    })
      .pipe(
        map(data => CustomerFactory.fromList(data))
      );
  }

  @Cacheable({
    cacheBusterObserver: CustomerService._cacheBuster$
  })
  searchInstructingEntities(companyNamePattern: string, page: number, size: number): Observable<LegalEntityList> {
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL, {params: {
        instructing: true,
        companyName: companyNamePattern,
        page,
        size
      }})
      .pipe(
        map(data => CustomerFactory.fromList(data))
      );
  }

  searchCore(instructing, text: string, page: number, size: number, instructingId: number) {
    const params = HttpParamsFactory.notNullsFromObject({
      searchText: text,
      page,
      size,
      instructing,
      instructingId
    });
    return this.httpClient.get<IObjectList<ILegalEntity>>(this.URL + '/core', {params})
      .pipe(
        map(list => CustomerFactory.fromList(list))
      );
  }
}
