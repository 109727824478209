import {Serializable} from '../interface/serializable.interface';
import {Selectable} from '../interface/selectable.interface';
import {StringJoiner} from '../joiner/string.joiner';
import {Address, AddressList} from './address.model';
import {IAddressList} from './interface/address.model.interface';
import {IObjectList, ObjectList} from './interface/object-list.model.interface';
import {IPhone, PhoneList} from './interface/phone.model.interface';
import {AddressType} from './interface/address-type.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {EmailList, IEmail} from './interface/email.model.interface';
import {StringBuilder} from 'typescript-string-operations';
import {ILessorGroup} from "./lessor-group.model";

export enum LegalEntityType {
  COMPANY = ('COMPANY'),
  COMPANY_TO_BE_VERIFIED = ('COMPANY_TO_BE_VERIFIED'),
  NATURAL_PERSON = ('NATURAL_PERSON'),
  NATURAL_PERSON_TO_BE_VERIFIED = ('NATURAL_PERSON_TO_BE_VERIFIED')
}

export interface ILegalEntity {
  type: LegalEntityType;
  active: boolean;
  id?: number;
  name?: string;
  phones: IObjectList<IPhone>;
  emails: IObjectList<IEmail>;
  secondName?: string;
  surname?: string;
  pesel?: string;
  companyName?: string;
  nip?: string;
  regon?: string;
  krs?: string;
  addresses: IAddressList;
  individualNumber?: string;
  group?: ILessorGroup;
  coreId: number;
}

export abstract class LegalEntity implements Serializable, Selectable {
  private readonly _id: number;
  private readonly _individualNumber: string;
  private readonly _addresses: AddressList;
  private readonly _phones: PhoneList;
  private readonly _emails: EmailList;
  private _active: boolean;
  protected _type: LegalEntityType;
  private _coreId: number;

  protected constructor(legalEntity: ILegalEntity) {
    this._id = legalEntity.id;
    this._type = legalEntity.type;
    this._individualNumber = legalEntity.individualNumber;
    if (legalEntity.emails) {
      this._emails = new EmailList(legalEntity.emails);
    }
    if (legalEntity.phones) {
      this._phones = new PhoneList(legalEntity.phones);
    }
    if (legalEntity.addresses) {
      this._addresses = new AddressList(legalEntity.addresses);
    }
    this._active = legalEntity.active;
    this._coreId = legalEntity.coreId
  }

  get individualNumber(): string {
    return this._individualNumber;
  }

  get id(): number {
    return this._id;
  }

  get coreId(): number {
    return this._coreId
  }

  get type(): LegalEntityType {
    return this._type;
  }

  get active(): boolean {
    return this._active;
  }

  set active(active) {
    this._active = active;
  }

  toText(): string {
    const joiner: StringJoiner = new StringJoiner('\n');
    if (this._individualNumber) {
      joiner.append(`Nr klienta: ${this._individualNumber}`);
    }
    joiner.append(`Email: ${this._emails.data.map(el => el.email).join(', ')}`);
    joiner.append(`Telefon: ${this._phones.data.map(el => el.phone).join(', ')}`);
    joiner.append(`${this.getAddresses()}`);
    return joiner.join();
  }

  getAddresses(): string {
    return this._addresses?.data
      .map(address => address.toText())
      .join('\n');
  }

  getEmails(): string[] {
    return this._emails.data.map(el => el.email);
  }

  get addresses(): AddressList {
    return this._addresses;
  }

  get phones(): PhoneList {
    return this._phones;
  }

  get emails(): EmailList {
    return this._emails;
  }

  get mainAddress(): Address {
    return this._addresses.data
      .find(address => address.type.type === AddressType.MAIN);
  }

  getPhones() {
    return this._phones.data.map(el => el.phone);
  }

  abstract toSelectText(): string;

  toHTML(): string {
    const joiner: StringJoiner = new StringJoiner('<br>');
    if (this._individualNumber) {
      joiner.append(`Nr klienta: <strong>${this._individualNumber}</strong>`);
    }
    joiner.append(`Email: ${this._emails.data.map(el => el.email).join(', ')}`);
    joiner.append(`Telefon: ${this._phones.data.map(el => el.phone).join(', ')}`);
    joiner.append(`${this._addresses.data.map(el => this.addressToHtml(el)).join('<br>')}`);
    return joiner.join();
  }

  addressToHtml(address: Address): string {
    const builder: StringBuilder = new StringBuilder();
    builder.Append(`<strong>${address.type.label}</strong>: <br>`);
    if (address.street) {
      builder.AppendFormat('{0} {1}', address.street, address.estateNumber);
    } else {
      builder.AppendFormat('{0} {1}', address.city, address.estateNumber);
    }
    if (address.localNumber) {
      builder.AppendFormat('/{0}', address.localNumber);
    }
    builder.AppendFormat(',<br>{0} {1}', address.postalCode, address.city);
    return builder.ToString();
  }
}


export class LegalEntityList extends ObjectList<LegalEntity, LegalEntity> {
  protected mapToListElement(instance: LegalEntity): LegalEntity {
    return instance;
  }

  protected mapToSelectableItem(instance: LegalEntity): ISimpleSelectableData {
    return {
      label: instance.toSelectText(),
      value: instance.id
    };
  }
}
