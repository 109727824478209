<div class="range" [formGroup]="rangeForm">
    <div class="picker">
        <app-simple-number-value-accessor [label]="label + ' od'"
                                        formControlName="from">
        </app-simple-number-value-accessor>
    </div>
    <div style="margin: 1rem;">
        -
    </div>
    <div class="picker">
        <app-simple-number-value-accessor [label]="label + ' do'"
                                        formControlName="to">
        </app-simple-number-value-accessor>
    </div>
</div>

